const testName = {
  1: "Stream finder",
  2: "Career Finder (p)",
  3: "Strength Finder",
  4: "Personality Analysis",
  5: "Interest Finder",
  6: "Career Finder (i)",
  7: "Career Finder (s)",
}

export default testName
