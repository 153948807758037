import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import testName from "./testName"
import classwiseTest from "./classwiseTest"

export default function Alltests({ userData }) {
  var ut = userData.tests
  var classNum = userData.Class
  console.log("user-", classNum, userData)

  return (
    <div className='m-4'>
      <div className='card'>
        <div className='card-body'>
          <h3>Student assessments</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
            dignissimos, hic eius qui vel cupiditate fugiat inventore autem
            aliquid magnam! Cumque quidem repudiandae pariatur labore possimus
            nesciunt dicta dolor qui corrupti autem, hic magnam expedita soluta
            corporis veritatis cupiditate, aliquid voluptate, deleniti eum
            minima. Distinctio odit provident qui maiores exercitationem,
            ratione a impedit ea quaerat dolorem in quod excepturi doloremque
            ad? Veniam iure distinctio nemo mollitia deserunt qui quasi nobis
            excepturi assumenda impedit, minus consectetur laboriosam et culpa!
            Incidunt, doloribus!
          </p>
        </div>
      </div>
      <h4 className='my-4 text-info' style={{ fontWeight: "700" }}>
        Active Tests
      </h4>
      {(() => {
        let exam = []
        let col = ["red", "green", "darkviolet", "orange"]
        let attempt = [
          ut ? ut["test1"]["attempt"] : true,
          ut ? ut["test2"]["attempt"] : true,
          ut ? ut["test3"]["attempt"] : true,
          ut ? ut["test4"]["attempt"] : true,
          ut ? ut["test5"]["attempt"] : true,
          ut ? ut["test6"]["attempt"] : true,
          ut ? ut["test7"]["attempt"] : true,
        ]
        if (classNum) {
          for (let i = 1; i <= 7; i++) {
            if (classNum && classwiseTest[classNum].includes(i) === false)
              continue
            if (attempt[i - 1] !== true) {
              exam.push(
                <div
                  key={i}
                  className='card my-3'
                  style={{ borderLeft: `8px solid ${col[(i - 1) % 4]}` }}
                >
                  <div className='row card-body'>
                    <div className='col-md-6'>
                      <span
                        className='h5 card-title'
                        style={{ color: "black" }}
                      >
                        {testName[i] ? testName[i] : `Test-${i}`}
                      </span>
                    </div>
                    <div
                      className='col-md-6'
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Link
                        to={`test-${i}`}
                        className='btn btn-sm btn-info'
                        style={{ marginLeft: "auto" }}
                      >
                        Attempt Now <i class='fas fa-pencil'></i>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            }
          }
        }

        return exam
      })()}

      <h4 className='my-4 text-info' style={{ fontWeight: "700" }}>
        Attempted Tests
      </h4>
      {(() => {
        let exam = []
        let col = ["red", "green", "darkviolet", "orange"]
        let attempt = [
          ut ? ut["test1"]["attempt"] : true,
          ut ? ut["test2"]["attempt"] : true,
          ut ? ut["test3"]["attempt"] : true,
          ut ? ut["test4"]["attempt"] : true,
          ut ? ut["test5"]["attempt"] : true,
          ut ? ut["test6"]["attempt"] : true,
          ut ? ut["test7"]["attempt"] : true,
        ]
        if (classNum) {
          for (let i = 1; i <= 7; i++) {
            if (classNum && classwiseTest[classNum].includes(i) === false)
              continue
            if (attempt[i - 1] === true) {
              exam.push(
                <div
                  key={i}
                  className='card my-3'
                  style={{ borderLeft: `8px solid ${col[(i - 1) % 4]}` }}
                >
                  <div className='row card-body'>
                    <div className='col-md-6'>
                      <span className='h5 card-title' style={{ color: "grey" }}>
                        {testName[i] ? testName[i] : `Test-${i}`}
                      </span>
                    </div>
                  </div>
                </div>
              )
            }
          }
        }

        return exam
      })()}
    </div>
  )
}
