export const navItems = [
  {
    id: 1,
    title: "Home",
    path: "/",
    cName: "nav-item",
  },
  {
    id: 2,
    title: "Our Services",
    path: "/services",
    cName: "nav-item",
  },
  {
    id: 3,
    title: "For Student",
    path: "/individual",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "For School",
    path: "/school",
    cName: "nav-item",
  },
  {
    id: 5,
    title: "For Franchise",
    path: "/franchise",
    cName: "nav-item",
  },
  {
    id: 6,
    title: "About Us",
    path: "/about",
    cName: "nav-item",
  },
  {
    id: 7,
    title: "Gallery",
    path: "/gallery",
    cName: "nav-item",
  },
  {
    id: 8,
    title: "Blog",
    path: "/blog",
    cName: "nav-item",
  },
  {
    id: 9,
    title: "Contact Us",
    path: "/contact",
    cName: "nav-item",
  },
]

export const serviceDropdown = [
  {
    id: 1,
    title: "For Student",
    path: "/individual",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "For School",
    path: "/school",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "For Franchise",
    path: "/franchise",
    cName: "submenu-item",
  },
  // {
  //   id: 3,
  //   title: "Design",
  //   path: "/design",
  //   cName: "submenu-item",
  // },
  // {
  //   id: 4,
  //   title: "Development",
  //   path: "/development",
  //   cName: "submenu-item",
  // },
]
