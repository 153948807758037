import React, { useEffect, useState } from "react"
import "./Login.css"
import { NavLink, useNavigate } from "react-router-dom"
import { Footer } from "../../components/Footer/Footer"
import { Header } from "../../components/Header/Header"
import useAuth from "../../Hooks/useAuth"

const Login = () => {
  // eslint-disable-next-line
  const { user, dispatch } = useAuth()

  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const loginUser = async (e) => {
    e.preventDefault()
    try {
      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
      const data = await res.json()
      // Debug
      // console.log(res);
      if (!data || res.status === 400 || res.status === 422) {
        window.alert("INVALID CREDENTIALS !")
      } else {
        dispatch({ type: "LOGIN", payload: data })
        localStorage.setItem("user", JSON.stringify(data))
        window.alert("USER LOGIN SUCCESSFULL !")
        navigate("/dashboard")
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (user) {
      navigate("/dashboard")
    }
  })

  return (
    <>
      <Header />
      <section className='login-section'>
        <form method='POST' className='login-modal '>
          <div className='login-header'>
            <h2>
              Login <br /> registered account
            </h2>
          </div>
          <div className='credentials'>
            <p>
              Email Address :
              <br />
              <input
                className='input'
                type='email'
                name='email'
                id='email'
                placeholder='Fill in your email address'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </p>
            <p>
              Password :
              <br />
              <input
                className='input'
                type='password'
                name='Password'
                id='pass'
                placeholder='Input your password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </p>
          </div>
          <input
            className='Login'
            type='submit'
            value='Log-In'
            onClick={loginUser}
          />
          <div className='login-footer'>
            <NavLink to='/signup'>
              <p>Don't have an account ?</p>
            </NavLink>
            <NavLink to='/signup'>
              <p>Create One</p>
            </NavLink>
          </div>
        </form>
      </section>
      <Footer />
    </>
  )
}

export default Login
